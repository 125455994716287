var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "", disabled: _vm.isSpaceResting } },
    [
      _c("v-card-title", [
        _c("span", { staticClass: "secondary--text font-weight-bold" }, [
          _vm._v("Applications"),
        ]),
      ]),
      _c(
        "v-card-text",
        [
          _vm.applicationsFetching && _vm.fetchingNodePools
            ? _c("v-skeleton-loader", {
                staticClass: "mx-auto",
                attrs: { type: "table" },
              })
            : [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    (_vm.isDevelopment && _vm.isSpaceAdmin) ||
                    (_vm.isDevelopment &&
                      _vm.currentSpaceType !== _vm.spaceTypes.EDUCATION_SPACE &&
                      _vm.isInstanceEditor)
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "mr-2",
                            attrs: {
                              to: {
                                name: "snapshot-applications-add",
                                params: {
                                  oid: _vm.$route.params.oid,
                                  sid: _vm.$route.params.sid,
                                  iid: _vm.$route.params.iid,
                                  snid: _vm.$route.params.snid,
                                },
                              },
                              color: "primary",
                              disabled:
                                _vm.applicationsFetching ||
                                _vm.isSpaceArchived ||
                                _vm.isSpaceInAnyRestStateExceptPreResting,
                            },
                          },
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v("add"),
                            ]),
                            _vm._v(" add new application "),
                          ],
                          1
                        )
                      : _c(
                          "v-dialog",
                          {
                            attrs: { "max-width": "500" },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "mr-2",
                                          attrs: {
                                            color: "primary",
                                            disabled:
                                              _vm.applicationsFetching ||
                                              _vm.isSpaceArchived ||
                                              _vm.isSpaceInAnyRestStateExceptPreResting,
                                          },
                                        },
                                        on
                                      ),
                                      [
                                        _c("v-icon", { attrs: { left: "" } }, [
                                          _vm._v("add"),
                                        ]),
                                        _vm._v(" add new application "),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.AddAppdialog,
                              callback: function ($$v) {
                                _vm.AddAppdialog = $$v
                              },
                              expression: "AddAppdialog",
                            },
                          },
                          [
                            _c(
                              "v-card",
                              [
                                _c("v-card-title", [
                                  _c(
                                    "span",
                                    { staticClass: "headline secondary--text" },
                                    [_vm._v("Insufficient privileges")]
                                  ),
                                ]),
                                _c("v-divider"),
                                _c("v-card-text", { staticClass: "mt-1" }, [
                                  _c("p", [
                                    _vm._v(
                                      " New applications can be added only by course admins. If you require an additional application for your work, please ask a course admin to add the application. As a reference, they can do it at the current URL: "
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-center justify-space-between mt-2",
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "span",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "secondary--text text-truncate",
                                                      },
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.currentURL.substring(
                                                            0,
                                                            100
                                                          )
                                                        ) + "..."
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.currentURL)),
                                          ]),
                                        ]
                                      ),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "div",
                                                    _vm._g({}, on),
                                                    [
                                                      _c("copy-to-clipboard", {
                                                        attrs: {
                                                          textToCopy:
                                                            _vm.currentURL,
                                                          buttonColor:
                                                            "secondary",
                                                          iconButton: true,
                                                          isSmall: true,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("Copy to clipboard"),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _c(
                                  "v-card-actions",
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { color: "primary", text: "" },
                                        on: {
                                          click: function ($event) {
                                            _vm.AddAppdialog = false
                                          },
                                        },
                                      },
                                      [_vm._v("close")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                    _vm.isInstanceEditor
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "mr-2",
                            attrs: { to: { name: "snapshot-images" } },
                          },
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v("mdi-export"),
                            ]),
                            _vm._v(" Exported Applications "),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("v-divider", {
                      staticClass: "mr-3 ml-1",
                      attrs: { vertical: "" },
                    }),
                    !_vm.isTrialSpace &&
                    _vm.applicationsWithStatus.length &&
                    _vm.selected.length > 0 &&
                    !(_vm.isDevelopment && _vm.isSpaceRested)
                      ? _c(
                          "v-btn",
                          {
                            staticClass: "mr-2",
                            on: {
                              click: function ($event) {
                                return _vm.addToStaging(_vm.selected)
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v("share"),
                            ]),
                            _vm._v(
                              " stage selected (" +
                                _vm._s(_vm.selected.length) +
                                ") "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-menu",
                      {
                        attrs: { "offset-y": "", bottom: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "caption mr-2",
                                      attrs: {
                                        color: "secondary",
                                        icon: "",
                                        disabled:
                                          _vm.isDevelopment &&
                                          _vm.isSpaceRested,
                                      },
                                    },
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("more_vert")])],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c(
                          "v-list",
                          {
                            staticClass: "py-4",
                            attrs: { nav: "", dense: "" },
                          },
                          [
                            _c(
                              "v-list-item",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "justify-start",
                                    attrs: {
                                      disabled:
                                        _vm.isTrialSpace ||
                                        _vm.applicationsWithStatus.length === 0,
                                      block: "",
                                      text: "",
                                      color: "secondary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addToStaging(
                                          _vm.applicationsWithStatus
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", { attrs: { left: "" } }, [
                                      _vm._v("share"),
                                    ]),
                                    _vm._v(
                                      " stage all (" +
                                        _vm._s(
                                          _vm.applicationsWithStatus.length
                                        ) +
                                        ") "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            !_vm.isTrialSpace &&
                            _vm.applicationsWithStatus.length
                              ? _c(
                                  "v-list-item",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "justify-start",
                                        attrs: {
                                          disabled: _vm.selected.length === 0,
                                          block: "",
                                          text: "",
                                          color: "secondary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addToStaging(
                                              _vm.selected
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", { attrs: { left: "" } }, [
                                          _vm._v("share"),
                                        ]),
                                        _vm._v(
                                          " stage selected (" +
                                            _vm._s(_vm.selected.length) +
                                            ") "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.isDevelopment &&
                            (_vm.isSpaceAdmin || _vm.isInstanceEditor)
                              ? _c("v-divider", { staticClass: "my-3" })
                              : _vm._e(),
                            _vm.isSpaceAdmin || _vm.isInstanceEditor
                              ? [
                                  _vm.isDevelopment
                                    ? _c(
                                        "v-list-item",
                                        {
                                          attrs: {
                                            "e2e-app-delete-for-me": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "app-delete-dialog",
                                            {
                                              attrs: {
                                                selected: _vm.selected,
                                                disabled:
                                                  _vm.selected.length === 0,
                                              },
                                              on: {
                                                finished: function ($event) {
                                                  _vm.selected = []
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "justify-start",
                                                  attrs: {
                                                    text: "",
                                                    block: "",
                                                    color: "secondary",
                                                    disabled:
                                                      _vm.selected.length === 0,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { left: "" } },
                                                    [_vm._v("delete")]
                                                  ),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getDeleteLabel
                                                      ) +
                                                      " "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.currentSpaceType !==
                                    _vm.spaceTypes.RESEARCH_SPACE &&
                                  _vm.isSpaceAdmin
                                    ? [
                                        _vm.isMasterInstance ||
                                        _vm.isDistributedInstance
                                          ? _c(
                                              "v-list-item",
                                              {
                                                attrs: {
                                                  "e2e-app-delete-for-others":
                                                    "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "app-delete-dialog",
                                                  {
                                                    attrs: {
                                                      selected: _vm.selected,
                                                      mode: 2,
                                                      disabled:
                                                        _vm.selected.length ===
                                                        0,
                                                    },
                                                    on: {
                                                      finished: function (
                                                        $event
                                                      ) {
                                                        _vm.selected = []
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "justify-start",
                                                        attrs: {
                                                          text: "",
                                                          block: "",
                                                          color: "secondary",
                                                          disabled:
                                                            _vm.selected
                                                              .length === 0,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: { left: "" },
                                                          },
                                                          [_vm._v("delete")]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.getDeleteForOthersLabel
                                                            ) +
                                                            " "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.isDevelopment &&
                                        _vm.isMasterInstance
                                          ? _c(
                                              "v-list-item",
                                              {
                                                attrs: {
                                                  "e2e-app-delete-for-all": "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "app-delete-dialog",
                                                  {
                                                    attrs: {
                                                      selected: _vm.selected,
                                                      mode: 1,
                                                      disabled:
                                                        _vm.selected.length ===
                                                        0,
                                                    },
                                                    on: {
                                                      finished: function (
                                                        $event
                                                      ) {
                                                        _vm.selected = []
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "justify-start",
                                                        attrs: {
                                                          text: "",
                                                          block: "",
                                                          color: "secondary",
                                                          disabled:
                                                            _vm.selected
                                                              .length === 0,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: { left: "" },
                                                          },
                                                          [_vm._v("delete")]
                                                        ),
                                                        _vm._v(
                                                          " Delete for all "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c("v-spacer"),
                    _c("v-text-field", {
                      staticClass: "mr-2",
                      attrs: {
                        "prepend-inner-icon": "mdi-filter",
                        autofocus: "",
                        "hide-details": "",
                        solo: "",
                        flat: "",
                        "background-color": "grey lighten-4",
                        dense: "",
                        label: "Search for an application...",
                        clearable: "",
                      },
                      on: { "click:clear": _vm.removeSearchQuery },
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    }),
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        icon: "",
                                        loading: _vm.applicationsFetching,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$store.dispatch(
                                            "snapshotStore/fetchApplications",
                                            _vm.$route.params.snid
                                          )
                                        },
                                      },
                                    },
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("refresh")])],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [_c("span", [_vm._v("Refresh Applications")])]
                    ),
                  ],
                  1
                ),
                _c("v-data-table", {
                  staticClass: "shepherd-app-staging-step-1",
                  attrs: {
                    search: _vm.search,
                    "show-select": "",
                    "item-key": "aid",
                    headers: _vm.headersFiltered,
                    options: _vm.tableOptions,
                    items: _vm.applicationsWithStatus,
                    "footer-props": {
                      "items-per-page-options": _vm.itemsPerPageOptions,
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "top",
                        fn: function ({ pagination, options, updateOptions }) {
                          return [
                            _c("v-data-footer", {
                              staticClass: "no-border",
                              attrs: {
                                pagination: pagination,
                                options: options,
                                itemsPerPageOptions: _vm.itemsPerPageOptions,
                                "items-per-page-text":
                                  "$vuetify.dataTable.itemsPerPageText",
                              },
                              on: { "update:options": updateOptions },
                            }),
                          ]
                        },
                      },
                      {
                        key: "no-data",
                        fn: function () {
                          return [
                            _c("span", [
                              _vm._v(
                                'No applications found. Consider adding a new application via the "Add New Application" button above.'
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: `item.icon`,
                        fn: function ({ item }) {
                          return [
                            _c("div", { staticClass: "d-flex" }, [
                              _c("img", {
                                attrs: {
                                  width: "30",
                                  height: "30",
                                  src: _vm.appIcon(item),
                                },
                              }),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `item.long_id`,
                        fn: function ({ item }) {
                          return [
                            _vm.isSpaceInAnyRestState
                              ? [
                                  (_vm.isSpacePreResting ||
                                    _vm.isSpaceRested) &&
                                  _vm.isDevelopment
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "text-none",
                                          attrs: { text: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openApp(item.aid)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.long_id) + " "
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "showOnHover",
                                              attrs: {
                                                right: "",
                                                color: "primary",
                                              },
                                            },
                                            [_vm._v("mdi-play-circle-outline")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _c("span", [
                                        _vm._v(_vm._s(item.long_id)),
                                      ]),
                                ]
                              : [
                                  _vm.isDevelopment && !_vm.isInstanceArchived
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "text-none",
                                          attrs: { text: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openApp(item.aid)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.long_id) + " "
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "showOnHover",
                                              attrs: {
                                                right: "",
                                                color: "primary",
                                              },
                                            },
                                            [_vm._v("mdi-play-circle-outline")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.long_id)),
                                          ]),
                                          _vm.isInstanceArchived
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                staticClass:
                                                                  "ml-1",
                                                                attrs: {
                                                                  small: "",
                                                                  icon: "",
                                                                  to: {
                                                                    name: "instance-snapshots",
                                                                    params: {
                                                                      oid: _vm
                                                                        .$route
                                                                        .params
                                                                        .oid,
                                                                      sid: _vm
                                                                        .$route
                                                                        .params
                                                                        .sid,
                                                                      iid: _vm
                                                                        .$route
                                                                        .params
                                                                        .iid,
                                                                      snid: _vm
                                                                        .$route
                                                                        .params
                                                                        .snid,
                                                                    },
                                                                  },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    {},
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "info"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      "This instance is archived, please restore a snapshot first"
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                ],
                          ]
                        },
                      },
                      {
                        key: `item.size`,
                        fn: function ({ item }) {
                          return [
                            _c(
                              "app-scale-info-menu",
                              { attrs: { app: item } },
                              [
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "text-none secondary--text",
                                    attrs: {
                                      text: "",
                                      to:
                                        _vm.currentSpaceType ===
                                          _vm.spaceTypes.EDUCATION_SPACE &&
                                        !_vm.isSpaceAdmin
                                          ? ""
                                          : {
                                              name: "application-scale",
                                              params: { aid: item.aid },
                                            },
                                    },
                                  },
                                  [
                                    item.active_resource === "ncu"
                                      ? [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                left: "",
                                                color: "primary",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "mdi-card-bulleted-outline"
                                              ),
                                            ]
                                          ),
                                          _vm._v(
                                            " " + _vm._s(`${item.ncu} CU`) + " "
                                          ),
                                        ]
                                      : [
                                          _vm.getNodepoolFamily(
                                            _vm.currentNodePool(item.node_pool)
                                          ) === "cpu"
                                            ? _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    left: "",
                                                    color: "purple",
                                                  },
                                                },
                                                [_vm._v("mdi-memory")]
                                              )
                                            : _vm._e(),
                                          _vm.getNodepoolFamily(
                                            _vm.currentNodePool(item.node_pool)
                                          ) === "gpu"
                                            ? _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    left: "",
                                                    color: "light-green",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " mdi-memory mdi-rotate-45 "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.getNodepoolFamily(
                                            _vm.currentNodePool(item.node_pool)
                                          ) === "ssd"
                                            ? _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    left: "",
                                                    color: "orange",
                                                  },
                                                },
                                                [_vm._v("mdi-nas")]
                                              )
                                            : _vm._e(),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                `${_vm.getNodepoolPrimaryParameter(
                                                  _vm.currentNodePool(
                                                    item.node_pool
                                                  )
                                                )}`
                                              ) +
                                              " "
                                          ),
                                        ],
                                    !(
                                      _vm.currentSpaceType ===
                                        _vm.spaceTypes.EDUCATION_SPACE &&
                                      !_vm.isSpaceAdmin
                                    )
                                      ? _c(
                                          "v-icon",
                                          {
                                            staticClass: "showOnHover",
                                            attrs: { right: "" },
                                          },
                                          [_vm._v("settings")]
                                        )
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `item.active_resource`,
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.active_resource === "ncu"
                                    ? "-"
                                    : `${_vm
                                        .currentNodePool(item.node_pool)
                                        .credits_per_hour.toFixed(2)}`
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                      {
                        key: `item.status`,
                        fn: function ({ item }) {
                          return [
                            item.status === "Stopped"
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      fab: "",
                                      "x-small": "",
                                      dark: "",
                                      color: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openApp(item.aid)
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("power_settings_new"),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.status === "Starting" ||
                            item.status === "Stopping"
                              ? _c(
                                  "stop-application",
                                  {
                                    attrs: {
                                      appName: item.long_id,
                                      appId: item.aid.toString(),
                                      isShared: item.shared === "true",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          fab: "",
                                          "x-small": "",
                                          dark: "",
                                          color: "deep-orange lighten-2",
                                        },
                                      },
                                      [
                                        _c("v-progress-circular", {
                                          attrs: {
                                            size: "18",
                                            color: "white",
                                            indeterminate: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.status === "Deleting"
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      fab: "",
                                      "x-small": "",
                                      dark: "",
                                      color: "blue-grey lighten-1",
                                    },
                                  },
                                  [
                                    _c("v-icon", { attrs: { small: "" } }, [
                                      _vm._v("delete"),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            item.status === "Running"
                              ? _c(
                                  "stop-application",
                                  {
                                    attrs: {
                                      appName: item.long_id,
                                      appId: item.aid.toString(),
                                      isShared: item.shared === "true",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          fab: "",
                                          "x-small": "",
                                          dark: "",
                                          color: "success",
                                        },
                                      },
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("power_settings_new"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                      {
                        key: `item.description`,
                        fn: function ({ item }) {
                          return [
                            item.image_description
                              ? _c(
                                  "version-description-viewer",
                                  {
                                    attrs: {
                                      name: item.long_id,
                                      iconUrl: item.icon_url,
                                      descriptionUrl: item.image_description,
                                    },
                                  },
                                  [
                                    _c("a", { staticClass: "subtitle-2" }, [
                                      _vm._v(_vm._s(item.description)),
                                    ]),
                                  ]
                                )
                              : _c(
                                  "span",
                                  { staticClass: "subtitle-2 secondary--text" },
                                  [_vm._v(_vm._s(item.description))]
                                ),
                          ]
                        },
                      },
                      {
                        key: `item.actions`,
                        fn: function ({ item }) {
                          return [
                            _c(
                              "div",
                              { staticClass: "d-flex flex-nowrap justify-end" },
                              [
                                !_vm.isTrialSpace &&
                                !(_vm.isDevelopment && _vm.isSpaceRested)
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "mr-2 showOnHover",
                                        attrs: {
                                          color: "secondary",
                                          small: "",
                                          outlined: "",
                                          text: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addToStaging([item])
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", { attrs: { left: "" } }, [
                                          _vm._v("share"),
                                        ]),
                                        _vm._v(" stage "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "v-menu",
                                  {
                                    attrs: { left: "", "offset-x": "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  {
                                                    staticClass:
                                                      "prestart_tour_1 mr-3",
                                                    attrs: {
                                                      text: "",
                                                      color: "secondary",
                                                      small: "",
                                                      disabled:
                                                        (_vm.isDistributedInstance &&
                                                          !_vm.isSpaceAdmin) ||
                                                        _vm.isSpaceRested ||
                                                        (_vm.isSpacePreResting &&
                                                          !_vm.isDevelopment),
                                                    },
                                                  },
                                                  on
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("more_horiz"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value:
                                        _vm.dropdownMenu[`app_${item.aid}`],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dropdownMenu,
                                          `app_${item.aid}`,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "dropdownMenu[`app_${item.aid}`]",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      {
                                        staticClass: "py-4",
                                        attrs: { nav: "", dense: "" },
                                      },
                                      [
                                        _c(
                                          "v-list-item",
                                          [
                                            !_vm.isSpaceInAnyRestStateExceptPreResting
                                              ? _c(
                                                  "application-config",
                                                  {
                                                    attrs: {
                                                      maxNCU:
                                                        _vm.currentSpaceType !==
                                                        _vm.spaceTypes
                                                          .EDUCATION_SPACE
                                                          ? 16
                                                          : 4,
                                                      aid: item.aid,
                                                      canSave:
                                                        _vm.isSpaceAdmin ||
                                                        (_vm.isInstanceEditor &&
                                                          _vm.currentSpaceType ===
                                                            _vm.spaceTypes
                                                              .RESEARCH_SPACE),
                                                      isTrial: _vm.isTrialSpace,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        staticClass:
                                                          "justify-start",
                                                        attrs: {
                                                          text: "",
                                                          block: "",
                                                          color: "secondary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.closeMenu(
                                                              item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: { left: "" },
                                                          },
                                                          [_vm._v("settings")]
                                                        ),
                                                        _vm._v(" Configure "),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _vm.isDevelopment
                                          ? [
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c(
                                                    "factory-reset",
                                                    { attrs: { app: item } },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "justify-start",
                                                          attrs: {
                                                            block: "",
                                                            text: "",
                                                            color: "secondary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.closeMenu(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                left: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "restart_alt"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " Factory reset "
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item",
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "justify-start",
                                                      attrs: {
                                                        block: "",
                                                        text: "",
                                                        color: "secondary",
                                                        to: {
                                                          query: {
                                                            resetAid: item.aid,
                                                          },
                                                        },
                                                        "e2e-app-clear-settings":
                                                          "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { left: "" } },
                                                        [
                                                          _vm._v(
                                                            "auto_fix_high"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " Clear Settings "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm.isSpaceAdmin
                                                ? _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "app-rename-dialog",
                                                        {
                                                          attrs: {
                                                            current: item,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "justify-start",
                                                              attrs: {
                                                                text: "",
                                                                block: "",
                                                                color:
                                                                  "secondary",
                                                                "e2e-app-rename":
                                                                  "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.closeMenu(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    left: "",
                                                                  },
                                                                },
                                                                [_vm._v("edit")]
                                                              ),
                                                              _vm._v(
                                                                " Rename application "
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              (_vm.spaceTypes.RESEARCH_SPACE &&
                                                (_vm.isSpaceAdmin ||
                                                  _vm.isInstanceEditor)) ||
                                              (!_vm.spaceTypes.RESEARCH_SPACE &&
                                                _vm.isSpaceAdmin)
                                                ? _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "app-clone-btn",
                                                        {
                                                          attrs: {
                                                            application: item,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "justify-start",
                                                              attrs: {
                                                                text: "",
                                                                block: "",
                                                                color:
                                                                  "secondary",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.closeMenu(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    left: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "animation"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" Clone "),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              item.status !== "Stopped"
                                                ? _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "stop-application",
                                                        {
                                                          attrs: {
                                                            appName:
                                                              item.long_id,
                                                            appId:
                                                              item.aid.toString(),
                                                            isShared:
                                                              item.shared ===
                                                              "true",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "justify-start",
                                                              attrs: {
                                                                block: "",
                                                                text: "",
                                                                color:
                                                                  "secondary",
                                                                "e2e-app-stop":
                                                                  "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    left: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-square"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" Stop "),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.isSpaceAdmin &&
                                              _vm.isMasterInstance
                                                ? _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "justify-start",
                                                          attrs: {
                                                            loading:
                                                              _vm.startingAppForAll,
                                                            text: "",
                                                            block: "",
                                                            color: "secondary",
                                                            "e2e-app-start-for-all":
                                                              "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.startAppForAll(
                                                                item.aoid
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                left: "",
                                                              },
                                                            },
                                                            [_vm._v("launch")]
                                                          ),
                                                          _vm._v(
                                                            " Start for all users "
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.isSpaceAdmin &&
                                              _vm.isMasterInstance &&
                                              _vm.currentSpaceType ===
                                                _vm.spaceTypes
                                                  .EDUCATION_SPACE &&
                                              !_vm.isSpaceArchived
                                                ? _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "app-schedule-dialog",
                                                        {
                                                          attrs: {
                                                            schedules:
                                                              _vm.schedules,
                                                            current: item,
                                                          },
                                                          on: {
                                                            fetchSchedules:
                                                              _vm.fetchSchedules,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "justify-start prestart_tour_2",
                                                              attrs: {
                                                                text: "",
                                                                block: "",
                                                                color:
                                                                  "secondary",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.closeMenu(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    left: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "schedule_send"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " Schedule for start "
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.isInstanceEditor &&
                                              !_vm.isSpaceArchived &&
                                              !_vm.isTrialSpace &&
                                              item.exportable
                                                ? _c("v-divider", {
                                                    staticClass: "my-3",
                                                  })
                                                : _vm._e(),
                                              _vm.isInstanceEditor &&
                                              !_vm.isSpaceArchived &&
                                              item.exportable
                                                ? _c(
                                                    "v-list-item",
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          staticClass:
                                                            "justify-start",
                                                          attrs: {
                                                            text: "",
                                                            color: "secondary",
                                                            block: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.showDockerTagDialogForApp(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                left: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-export"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(
                                                            " Export application "
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.isSpaceAdmin ||
                                              _vm.isInstanceEditor
                                                ? _c("v-divider", {
                                                    staticClass: "my-3",
                                                  })
                                                : _vm._e(),
                                            ]
                                          : _vm._e(),
                                        _vm.isSpaceAdmin || _vm.isInstanceEditor
                                          ? [
                                              _vm.isDevelopment
                                                ? _c(
                                                    "v-list-item",
                                                    {
                                                      attrs: {
                                                        "e2e-app-delete-for-me":
                                                          "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "app-delete-dialog",
                                                        {
                                                          attrs: {
                                                            selected: [item],
                                                            disabled:
                                                              item.status ===
                                                              "Running",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "justify-start",
                                                              attrs: {
                                                                text: "",
                                                                block: "",
                                                                color:
                                                                  "secondary",
                                                                disabled:
                                                                  item.status ===
                                                                  "Running",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.closeMenu(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  attrs: {
                                                                    left: "",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "delete"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.getDeleteLabel
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.currentSpaceType !==
                                                _vm.spaceTypes.RESEARCH_SPACE &&
                                              _vm.isSpaceAdmin
                                                ? [
                                                    _vm.isMasterInstance ||
                                                    _vm.isDistributedInstance
                                                      ? _c(
                                                          "v-list-item",
                                                          {
                                                            attrs: {
                                                              "e2e-app-delete-for-others":
                                                                "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "app-delete-dialog",
                                                              {
                                                                attrs: {
                                                                  selected: [
                                                                    item,
                                                                  ],
                                                                  mode: 2,
                                                                  disabled:
                                                                    item.status ===
                                                                    "Running",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "justify-start",
                                                                    attrs: {
                                                                      text: "",
                                                                      block: "",
                                                                      color:
                                                                        "secondary",
                                                                      disabled:
                                                                        item.status ===
                                                                        "Running",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.closeMenu(
                                                                            item
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          left: "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "delete"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.getDeleteForOthersLabel
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm.isDevelopment &&
                                                    _vm.isMasterInstance
                                                      ? _c(
                                                          "v-list-item",
                                                          {
                                                            attrs: {
                                                              "e2e-app-delete-for-all":
                                                                "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "app-delete-dialog",
                                                              {
                                                                attrs: {
                                                                  selected: [
                                                                    item,
                                                                  ],
                                                                  mode: 1,
                                                                  disabled:
                                                                    item.status ===
                                                                    "Running",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    staticClass:
                                                                      "justify-start",
                                                                    attrs: {
                                                                      text: "",
                                                                      block: "",
                                                                      color:
                                                                        "secondary",
                                                                      disabled:
                                                                        item.status ===
                                                                        "Running",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.closeMenu(
                                                                            item
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      {
                                                                        attrs: {
                                                                          left: "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "delete"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      " Delete for all "
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                : _vm._e(),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `item.lib_usage`,
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.lib_usage === null
                                    ? ""
                                    : _vm.humanFileSize(item.lib_usage)
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.selected,
                    callback: function ($$v) {
                      _vm.selected = $$v
                    },
                    expression: "selected",
                  },
                }),
              ],
        ],
        2
      ),
      _vm.isSpaceAdmin &&
      _vm.currentSpaceType === _vm.spaceTypes.EDUCATION_SPACE &&
      _vm.isMasterInstance
        ? [
            _c("v-card-subtitle", [_vm._v("Schedules")]),
            _c(
              "v-card-text",
              [
                [
                  _c("v-data-table", {
                    staticClass: "pb-12",
                    attrs: {
                      "item-key": "aid",
                      headers: _vm.scheduleHeaders,
                      items: _vm.filteredSchedules,
                      loading: _vm.loading,
                      options: _vm.tableOptions,
                      "footer-props": {
                        "items-per-page-options": _vm.itemsPerPageOptions,
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "top",
                          fn: function ({
                            pagination,
                            options,
                            updateOptions,
                          }) {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "ma-0 pa-0",
                                    attrs: {
                                      "hide-details": "",
                                      label: "Show past schedules too",
                                      disabled: _vm.loading,
                                    },
                                    model: {
                                      value: _vm.showOldSchedules,
                                      callback: function ($$v) {
                                        _vm.showOldSchedules = $$v
                                      },
                                      expression: "showOldSchedules",
                                    },
                                  }),
                                  _c("v-spacer"),
                                  _c("v-data-footer", {
                                    staticClass: "no-border",
                                    attrs: {
                                      pagination: pagination,
                                      options: options,
                                      itemsPerPageOptions:
                                        _vm.itemsPerPageOptions,
                                      "items-per-page-text":
                                        "$vuetify.dataTable.itemsPerPageText",
                                    },
                                    on: { "update:options": updateOptions },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                        {
                          key: "no-data",
                          fn: function () {
                            return [
                              _c("span", [
                                _vm._v(
                                  'No scheduled applications found. Consider scheduling a new application via the "Schedule for start" button in actions.'
                                ),
                              ]),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: `item.icon`,
                          fn: function ({ item }) {
                            return [
                              _c("div", { staticClass: "d-flex" }, [
                                _c("img", {
                                  attrs: {
                                    width: "30",
                                    height: "30",
                                    src: _vm.appIcon(item),
                                  },
                                }),
                              ]),
                            ]
                          },
                        },
                        {
                          key: `item.long_id`,
                          fn: function ({ item }) {
                            return [
                              _vm.isDevelopment && !_vm.isInstanceArchived
                                ? _c("div", { staticClass: "subtitle-2" }, [
                                    _vm._v(" " + _vm._s(item.long_id) + " "),
                                  ])
                                : _c(
                                    "div",
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(item.long_id)),
                                      ]),
                                      _vm.isInstanceArchived
                                        ? _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            staticClass: "ml-1",
                                                            attrs: {
                                                              small: "",
                                                              icon: "",
                                                              to: {
                                                                name: "instance-snapshots",
                                                                params: {
                                                                  oid: _vm
                                                                    .$route
                                                                    .params.oid,
                                                                  sid: _vm
                                                                    .$route
                                                                    .params.sid,
                                                                  iid: _vm
                                                                    .$route
                                                                    .params.iid,
                                                                  snid: _vm
                                                                    .$route
                                                                    .params
                                                                    .snid,
                                                                },
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g({}, on),
                                                              [_vm._v("info")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "This instance is archived, please restore a snapshot first"
                                                ),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                            ]
                          },
                        },
                        {
                          key: `item.startup_time`,
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dateTimeToHuman")(item.startup_time)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.type_value`,
                          fn: function ({ item }) {
                            return [
                              item.node_pool
                                ? _c("v-chip", { attrs: { small: "" } }, [
                                    _vm._v(_vm._s(item.type_value)),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: `item.stop_after_minutes`,
                          fn: function ({ item }) {
                            return [
                              item.node_pool
                                ? _c("div", [
                                    _vm._v(_vm._s(item.stop_after_minutes)),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: `item.actions`,
                          fn: function ({ item }) {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex flex-nowrap justify-end",
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "showOnHover mr-1",
                                      attrs: {
                                        disabled:
                                          _vm.isSpaceArchived ||
                                          _vm.disableScheduleNextWeek(item) ||
                                          _vm.loading,
                                        outlined: "",
                                        small: "",
                                        color: "primary",
                                        text: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setScheduleNextWeek(
                                            item.aoid,
                                            item.startup_time,
                                            item?.node_pool,
                                            item?.stop_after_minutes
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { left: "", small: "" } },
                                        [_vm._v("navigate_next")]
                                      ),
                                      _vm._v(" Add to next week "),
                                    ],
                                    1
                                  ),
                                  !item.past && !_vm.loading
                                    ? _c(
                                        "app-schedule-dialog",
                                        {
                                          attrs: {
                                            schedules: _vm.schedules,
                                            current: item,
                                            crud: "update",
                                          },
                                          on: {
                                            fetchSchedules: _vm.fetchSchedules,
                                          },
                                        },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "mr-1",
                                                                attrs: {
                                                                  icon: "",
                                                                  small: "",
                                                                  color:
                                                                    "secondary",
                                                                },
                                                              },
                                                              "v-btn",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  small: "",
                                                                },
                                                              },
                                                              [_vm._v("edit")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [_c("span", [_vm._v("Edit")])]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          small: "",
                                                          color: "secondary",
                                                          disabled: _vm.loading,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.deleteSchedule(
                                                              item.ssid
                                                            )
                                                          },
                                                        },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [_vm._v("delete")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [_c("span", [_vm._v("Delete")])]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
              ],
              2
            ),
          ]
        : _vm._e(),
      _c("ResetApplicationDialog"),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600px", "lazy-validation": "" },
          model: {
            value: _vm.dockerExportTagDialog,
            callback: function ($$v) {
              _vm.dockerExportTagDialog = $$v
            },
            expression: "dockerExportTagDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v("Choose an optional tag for your app"),
              ]),
              _c("v-card-subtitle", { staticClass: "caption" }, [
                _vm._v("A random tag will be generated if none given."),
              ]),
              _c(
                "v-card-text",
                { staticClass: "mb-0" },
                [
                  _c(
                    "v-form",
                    {
                      model: {
                        value: _vm.dockerExportTagIsValid,
                        callback: function ($$v) {
                          _vm.dockerExportTagIsValid = $$v
                        },
                        expression: "dockerExportTagIsValid",
                      },
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          outlined: "",
                          hint: "Lower case letters, numbers, dashes and underscores only",
                          rules: [_vm.rules.validChars],
                          dense: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function () {
                              return [_vm._v("Type a tag here")]
                            },
                            proxy: true,
                          },
                        ]),
                        model: {
                          value: _vm.dockerExportTag,
                          callback: function ($$v) {
                            _vm.dockerExportTag = $$v
                          },
                          expression: "dockerExportTag",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.isRunning
                ? _c(
                    "v-card-text",
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "my-0",
                          attrs: {
                            prominent: "",
                            outlined: "",
                            type: "warning",
                          },
                        },
                        [
                          _vm._v(
                            " We recommend stopping your application before exporting to prevent possible inconsitencies. "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-card-actions",
                [
                  _c("v-checkbox", {
                    scopedSlots: _vm._u([
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("span", { staticClass: "caption" }, [
                              _vm._v("Do not export workspace files"),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.skipFileExport,
                      callback: function ($$v) {
                        _vm.skipFileExport = $$v
                      },
                      expression: "skipFileExport",
                    },
                  }),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.dockerExportTagDialog = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        color: "primary",
                        disabled: !_vm.dockerExportTagIsValid,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.exportToImage()
                        },
                      },
                    },
                    [_vm._v("Export")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isSpaceRested
        ? _c(
            "v-dialog",
            {
              attrs: { width: "500" },
              model: {
                value: _vm.wakeSpaceDialog,
                callback: function ($$v) {
                  _vm.wakeSpaceDialog = $$v
                },
                expression: "wakeSpaceDialog",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    [
                      _c("v-icon", { attrs: { left: "", color: "primary" } }, [
                        _vm._v("mdi-bell-ring-outline"),
                      ]),
                      _vm._v(" Wake up space "),
                    ],
                    1
                  ),
                  _c("v-card-text", { staticClass: "pt-3" }, [
                    _c("div", [
                      _vm._v(
                        " Before the application can be started, the space will be removed from resting state. This can take a couple of minutes depending on the total size of the storage in this space. "
                      ),
                    ]),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "secondary", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.wakeSpaceDialog = false
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.wakeSpace()
                            },
                          },
                        },
                        [_vm._v("Continue")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }